const toastr = require("toastr");

toastr.options = {
  positionClass: "toast-top-center",
  showMethod: "slideDown",
  hideMethod: "slideUp",
  closeButton: true,
  progressBar: false,
  closeDuration: 100,
  preventDuplicates: true,
  autoDismiss: true,
  maxOpened: 1,
};

// You can delete this file if you're not using it
exports.onInitialClientRender = () => {
  window.addEventListener("popstate", () => window.location.href);
};
